import { PlatformEventTriggerOptionValues, SelectItem } from 'types';

export const PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES = {
    ALL: 'all',
    MALICIOUS: 'malicious',
    NONE: 'none',
};

export const PLATFORM_EVENT_TRIGGER_OPTIONS = {
    [PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.ALL]: 1,
    [PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.MALICIOUS]: 2,
    [PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.NONE]: 3,
};

export const PLATFORM_EVENT_TRIGGER_SELECT_ITEMS: Record<PlatformEventTriggerOptionValues, SelectItem<PlatformEventTriggerOptionValues>> = {
    [PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.ALL]]: {
        value: PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.ALL],
        label: `[DEFAULT] Automatically trigger exclusion events for all threat groups (cq_label won't be passed to the dataLayer)`,
    },
    [PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.MALICIOUS]]: {
        value: PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.MALICIOUS],
        label: 'Trigger exclusion events only for malicious threat groups (cq_label will be passed to the dataLayer for other threat groups)',
    },
    [PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.NONE]]: {
        value: PLATFORM_EVENT_TRIGGER_OPTIONS[PLATFORM_EVENT_TRIGGER_OPTIONS_NAMES.NONE],
        label: "Do not trigger automatic exclusion events for any threat group (cq_label will be passed to the dataLayer for all threat groups)",
    },
};

export const ALLOWED_FEATURE_BLOCKS = {
    CONSENT: 'audience',
    CONNECTOR: 'connector',
};

export const ALLOWED_FEATURE_BLOCKS_VALUES = {
    [ALLOWED_FEATURE_BLOCKS.CONSENT]: 1,
    [ALLOWED_FEATURE_BLOCKS.CONNECTOR]: 2,
};
